import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b2b63f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "body-container" }
const _hoisted_3 = { class: "history-area" }
const _hoisted_4 = { class: "normal-row" }
const _hoisted_5 = { class: "row mobile-ver" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "row" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "mail" }
const _hoisted_10 = {
  class: "row",
  style: {"line-height":"15px"}
}
const _hoisted_11 = { class: "mail time" }
const _hoisted_12 = {
  key: 0,
  class: "thin-divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_Popup, {
        key: 0,
        onOnHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.unsetPopup('ContractHistory'))),
        onOnClickOutsidePopup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.unsetPopup('ContractHistory'))),
        title: _ctx.contractTitle + _ctx.$t('sign.contractHistoryTitle')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractHistory, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("img", {
                          src: require('icons/primary-history.svg'),
                          alt: "icon",
                          class: "icon mr-1"
                        }, null, 8, _hoisted_6),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("p", {
                              class: "name",
                              innerHTML: item.action
                            }, null, 8, _hoisted_8)
                          ]),
                          _createVNode(_component_Vue3Popper, {
                            content: item.email
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_9, _toDisplayString(item.email), 1)
                            ]),
                            _: 2
                          }, 1032, ["content"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("sign.date")) + " " + _toDisplayString(_ctx.formatDate(item.date)), 1)
                      ])
                    ]),
                    (index != _ctx.contractHistory.length - 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}