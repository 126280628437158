import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f80210c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "imprint-container"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "circle" }
const _hoisted_5 = {
  key: 2,
  class: "imprint-container"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "circle square" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_icon = _resolveComponent("number-icon")!

  return (_ctx.field.type === 2)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "stamp-sign",
        style: _normalizeStyle({
      width: `${_ctx.field.width}px`,
      height: `${_ctx.field.height}px`,
      top: `${_ctx.field.positionY}px`,
      left: `${_ctx.field.positionX}px`,
    })
      }, [
        (!_ctx.readonly)
          ? (_openBlock(), _createBlock(_component_number_icon, {
              key: 0,
              number: _ctx.order,
              "is-check": _ctx.field.isFilled
            }, null, 8, ["number", "is-check"]))
          : _createCommentVNode("", true),
        (_ctx.field?.imprintType === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.readonly)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: 
          _ctx.isPreview
            ? require('images/stamp-sign.svg')
            : require('images/stamp-grey.svg')
        ,
                    class: "stamp-image mt-2"
                  }, null, 8, _hoisted_2))
                : (_ctx.field.text == '')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: _ctx.field.base64 ? _ctx.field.base64 : require('images/stamp-sign.svg'),
                      class: _normalizeClass(["stamp-image pointer", {
          circle: _ctx.field.base64,
        }]),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onShowUploadStamp(_ctx.field.id)))
                    }, null, 10, _hoisted_3))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onShowUploadStamp(_ctx.field.id))),
                      class: "stamp-image pointer"
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", {
                          class: "text",
                          style: _normalizeStyle({
              fontSize: `${
                _ctx.field.text?.length > 18
                  ? (10 * _ctx.field.width) / 79
                  : (12 * _ctx.field.width) / 79
              }px`,
              width: `calc(100% - ${(15 * _ctx.field.width) / 79}px)`,
              maxHeight: `calc(100% - ${
                _ctx.field.text?.length >= 12
                  ? (15 * _ctx.field.width) / 79
                  : (10 * _ctx.field.width) / 79
              }px)`,
            })
                        }, _toDisplayString(_ctx.field.text?.length > 19
                ? _ctx.field.text.substring(0, 18).concat("...")
                : _ctx.field.text), 5)
                      ])
                    ]))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.readonly)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: 
          _ctx.isPreview
            ? require('images/square-stamp-sign.svg')
            : require('images/square-stamp-grey.svg')
        ,
                    class: "full-size mt-2"
                  }, null, 8, _hoisted_6))
                : (_ctx.field.text == '')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: 
          _ctx.field.base64 ? _ctx.field.base64 : require('images/square-stamp-sign.svg')
        ,
                      class: _normalizeClass(["full-size pointer", {
          'circle square': _ctx.field.base64,
        }]),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onShowUploadStamp(_ctx.field.id)))
                    }, null, 10, _hoisted_7))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onShowUploadStamp(_ctx.field.id))),
                      class: "full-size pointer"
                    }, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", {
                          class: "text",
                          style: _normalizeStyle({
              fontSize: `${
                _ctx.field.text?.length > 18
                  ? (10 * _ctx.field.width) / 79
                  : (12 * _ctx.field.width) / 79
              }px`,
              width: `calc(100% - ${(15 * _ctx.field.width) / 79}px)`,
              maxHeight: `calc(100% - ${
                _ctx.field.text?.length >= 12
                  ? (15 * _ctx.field.width) / 79
                  : (10 * _ctx.field.width) / 79
              }px)`,
            })
                        }, _toDisplayString(_ctx.field.text?.length > 19
                ? _ctx.field.text.substring(0, 18).concat("...")
                : _ctx.field.text), 5)
                      ])
                    ]))
            ]))
      ], 4))
    : _createCommentVNode("", true)
}