import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chip" }
const _hoisted_2 = { class: "leading" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.avatar)
        ? (_openBlock(), _createBlock(_component_AvatarCircle, {
            key: 0,
            class: "avatar",
            avatar: _ctx.avatar,
            alt: "avatar"
          }, null, 8, ["avatar"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
    ]),
    (_ctx.canRemove)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRemove && _ctx.onRemove(...args))),
          class: "close-icon",
          src: require('icons/close.svg'),
          alt: "close"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}