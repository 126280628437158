import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2ecaae6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contract-popup" }
const _hoisted_2 = { class: "contract-message mt-5" }
const _hoisted_3 = { class: "message" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "access-input mt-5" }
const _hoisted_7 = { class: "confirm-button sign-btn" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.contractTitle + _ctx.$t('sign.signingContract'),
        onOnClickOutsidePopup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLoading ? null : _ctx.hide())),
        onOnHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isLoading ? null : _ctx.hide())),
        class: "contract-popup"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, [
                _createElementVNode("span", {
                  class: "accent-text",
                  innerHTML: _ctx.$t('sign.loginEmail', { email: _ctx.signerEmail })
                }, null, 8, _hoisted_4),
                _createElementVNode("span", {
                  innerHTML: _ctx.$t('sign.popupMessage3')
                }, null, 8, _hoisted_5)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ValidationTextField, {
                rules: "required",
                name: "signPasscode",
                message: {
            error_required: _ctx.$t('errors.required'),
          },
                value: _ctx.code,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
                type: "text",
                placeholder: _ctx.$t('sign.popupPlaceholder')
              }, null, 8, ["message", "value", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "message",
                    innerHTML: _ctx.$t('sign.inSignatureText')
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true),
              _createVNode(_component_FlatButton, {
                onOnLoading: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoading = $event)),
                "on-click": _ctx.onSign,
                text: _ctx.$t('sign.sign'),
                enabled: _ctx.code != ''
              }, null, 8, ["on-click", "text", "enabled"]),
              _createVNode(_component_TextButton, {
                class: "resend-code-btn",
                text: _ctx.$t('sign.requestCodeAgain'),
                onClick: _ctx.onRequestCodeAgain
              }, null, 8, ["text", "onClick"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}