import { FileInfo } from "@/models/contracts/FileInfo";
import { FileInput } from "@/models/contracts/FileInput";

import stampImage from "@/assets/images/stamp-orange.svg";
import stampSquareImage from "@/assets/images/square-stamp-orange.svg";
import textImage from "@/assets/images/text.svg";
import checkboxImage from "@/assets/images/checkbox.svg";
import {
  getStampHeightFromWidth,
  getStampWidthFromMM,
} from "@/lib/utility/common";

//setting image for dragging var fields, prevent asyncronous initialize image cause sometimes the image is hidden at the first time
const textImg = new Image();
textImg.src = textImage;

const checkboxImg = new Image();
checkboxImg.src = checkboxImage;

const stampImg = new Image();
stampImg.src = stampImage;

const stampSquareImg = new Image();
stampSquareImg.src = stampSquareImage;

const fontSizes = [
  {
    id: "8",
    name: "8",
    mobVal: "7",
  },
  {
    id: "9",
    name: "9",
    mobVal: "8",
  },
  {
    id: "10",
    name: "10",
    mobVal: "9",
  },
  {
    id: "11",
    name: "11",
    mobVal: "10",
  },
  {
    id: "12",
    name: "12",
    mobVal: "11",
  },
  {
    id: "14",
    name: "14",
    mobVal: "12",
  },
];

export default function useInputVars() {
  const dragStamp = (e: any, file: any, stampType: any) => {
    e.dataTransfer.setData("field", "stamp");
    e.dataTransfer.setData("fileId", file.id);
    e.dataTransfer.setDragImage(
      stampType === 1 ? stampImg : stampSquareImg,
      25,
      25
    );
  };
  const dragText = (e: any, file: any) => {
    e.dataTransfer.setData("field", "text");
    e.dataTransfer.setData("fileId", file.id);
    e.dataTransfer.setDragImage(textImg, 0, 0);
  };
  const dragCheckbox = (e: any, file: any) => {
    e.dataTransfer.setData("field", "checkbox");
    e.dataTransfer.setData("fileId", file.id);
    e.dataTransfer.setDragImage(checkboxImg, 0, 0);
  };
  const addVarFromDrag = (
    e: any,
    file: any,
    stampType: string,
    stampSize: number
  ) => {
    const fieldType = e.dataTransfer.getData("field");
    const page = e.target.getBoundingClientRect();

    let actualX = Math.floor(e.pageX - page.left - e.target.offsetLeft);
    let actualY = Math.floor(e.pageY - page.top - e.target.offsetTop);

    const scale = file.scale || 1;

    if (fieldType === "stamp") {
      let defaultWidthOfStamp = getStampWidthFromMM(stampSize || 21);
      let defaultHeightOfStamp = getStampHeightFromWidth(
        defaultWidthOfStamp / 2
      );
      defaultWidthOfStamp = defaultWidthOfStamp * scale;
      defaultHeightOfStamp = defaultHeightOfStamp * scale;
      const padding = 10 * 2 * scale;

      if (actualX + defaultWidthOfStamp + padding > page.width)
        actualX = page.width - defaultWidthOfStamp - padding;
      else actualX = actualX - 75;

      if (actualX <= 0) actualX = 0;

      if (actualY + defaultHeightOfStamp + padding > page.height)
        actualY = page.height - defaultHeightOfStamp - padding;
      else actualY = actualY - 40;

      if (actualY <= 0) actualY = 0;

      addStamp(file, file.currentPage, {
        initX: Math.floor(actualX),
        initY: Math.floor(actualY),
        isMobile: false,
        isRound: stampType === "square" ? false : true,
        stampSize: stampSize,
      });
    } else if (fieldType === "text") {
      const defaultWidth = Math.floor(300 * file.scale);
      const defaultHeight = Math.floor(40 * file.scale);
      if (actualX + defaultWidth > page.width)
        actualX = page.width - defaultWidth;
      if (actualY + defaultHeight > page.height)
        actualY = page.height - defaultHeight;
      addText(file, false, file.currentPage, {
        initX: Math.floor(actualX > 0 ? actualX : 0),
        initY: Math.floor(actualY > 0 ? actualY : 0),
        isMobile: false,
      });
    } else if (fieldType === "checkbox") {
      const defaultWidth = Math.floor(300 * file.scale);
      const defaultHeight = Math.floor(100 * file.scale);
      if (actualX + defaultWidth > page.width)
        actualX = page.width - defaultWidth;
      if (actualY + defaultHeight > page.height)
        actualY = page.height - defaultHeight;
      addText(file, true, file.currentPage, {
        initX: Math.floor(actualX > 0 ? actualX : 0),
        initY: Math.floor(actualY > 0 ? actualY : 0),
        isMobile: false,
      });
    }
  };

  const addStamp = (
    file: FileInfo,
    page: number,
    options = {
      initX: 0,
      initY: 0,
      isMobile: false,
      isRound: true,
      stampSize: 21,
    }
  ) => {
    let defaultWidthOfStamp = getStampWidthFromMM(options.stampSize);
    let defaultHeightOfStamp = getStampHeightFromWidth(defaultWidthOfStamp / 2);
    const scale = file.scale ?? 1;

    defaultWidthOfStamp = defaultWidthOfStamp * scale;
    defaultHeightOfStamp = defaultHeightOfStamp * scale;
    const inputId = new Date().getTime().toString();
    const stampInput = new FileInput(
      "stamps" + inputId,
      file.id,
      2,
      page,
      "",
      "",
      false,
      false,
      0,
      options.initX,
      options.initY,
      defaultWidthOfStamp,
      defaultHeightOfStamp,
      10 * scale,
      options.isRound,
      "10",
      file.scale ?? 1.0
    );
    file.inputs.push(stampInput);
  };

  const addText = (
    file: FileInfo,
    isOptional: boolean,
    page: number,
    options = {
      initX: 0,
      initY: 0,
      isMobile: false,
    }
  ) => {
    let defaultWidth = 300;
    let defaultHeight = isOptional ? 100 : 40;
    const scale = file.scale ?? 1;

    if (options.isMobile) {
      if (isOptional) {
        defaultWidth = 250;
        defaultHeight = 100;
      } else {
        defaultWidth = 200;
        defaultHeight = 40;
      }
    } else {
      defaultWidth = Math.floor(defaultWidth * scale);
      defaultHeight = Math.floor(defaultHeight * scale);
    }

    const minHeight = Math.floor(40 * scale);
    const inputId = new Date().getTime().toString();
    const textInput = new FileInput(
      "texts" + inputId,
      file.id,
      isOptional ? 3 : 1,
      page,
      "",
      "",
      false,
      !isOptional,
      0,
      options.initX,
      options.initY,
      defaultWidth,
      defaultHeight,
      0,
      false,
      "10",
      file.scale ?? 1.0,
      minHeight
    );
    file.inputs.push(textInput);
  };

  const onRemoveInput = (file: FileInfo, fieldId: string) => {
    const fieldIndex = file.inputs.findIndex(
      (field: any) => field.id === fieldId
    );
    if (fieldIndex !== -1) (file.inputs as Array<any>).splice(fieldIndex, 1);
  };

  const updateField = (file: FileInfo, fieldId: string, payload: any) => {
    const fieldIndex = file.inputs.findIndex(
      (field: any) => field.id === fieldId
    );
    const field = file.inputs[fieldIndex];
    field.x = payload.x;
    field.y = payload.y;
    field.h = payload.h;
    field.w = payload.w;
  };

  const updateSigner = (file: FileInfo, fieldId: string, payload: any) => {
    const fieldIndex = file.inputs.findIndex(
      (field: any) => field.id === fieldId
    );

    const field = file.inputs[fieldIndex];
    field.contractSignerOrder = payload.order;
    field.signer = payload.id;
  };

  const updateCheckedByDefault = (
    file: FileInfo,
    fieldId: string,
    payload: any
  ) => {
    const fieldIndex = file.inputs.findIndex(
      (field: any) => field.id === fieldId
    );

    const field = file.inputs[fieldIndex];
    field.isChecked = payload;
  };

  const updateIsRequired = (file: FileInfo, fieldId: string, payload: any) => {
    const fieldIndex = file.inputs.findIndex(
      (field: any) => field.id === fieldId
    );

    const field = file.inputs[fieldIndex];
    field.isOptionalText = payload;
  };

  const updateCheckBoxText = (
    file: FileInfo,
    fieldId: string,
    payload: any
  ) => {
    const fieldIndex = file.inputs.findIndex(
      (field: any) => field.id === fieldId
    );

    const field = file.inputs[fieldIndex];
    field.text = payload;
  };

  const updateFontSize = (file: FileInfo, fieldId: string, payload: any) => {
    const fieldIndex = file.inputs.findIndex(
      (field: any) => field.id === fieldId
    );

    const field = file.inputs[fieldIndex];
    field.fontSize = payload;
  };

  const onPageScaled = (file: any, scale: number) => {
    if (!file.scale || file.scale == 1) {
      file.scale = scale;
      file.inputs.forEach((input: FileInput) => {
        if (file.scale >= 1) {
          input.applyScale(scale);
        } else {
          input.applyScaleMobile(scale);
        }
      });
    } else if (file.scale != scale) {
      // file.inputs.forEach((input: FileInput) => {
      //   if (file.scale >= 1) {
      //     input.applyScale(1 / scale);
      //     input.applyScale(scale);
      //   } else {
      //     input.applyScaleMobile(1 / scale);
      //     input.applyScaleMobile(scale);
      //   }
      // });
      // file.scale = scale;
    }
  };

  const onPageSized = (file: FileInfo, size: any) => {
    file.width = size.width ?? 0;
    file.height = size.height ?? 0;
  };

  const getFontSizeMobile = (fontSize: string): string => {
    return fontSizes.find((e) => e.id == fontSize)?.mobVal ?? "9";
  };

  return {
    fontSizes,

    addText,
    addStamp,
    onRemoveInput,
    updateField,
    updateSigner,
    updateCheckedByDefault,
    updateIsRequired,
    updateCheckBoxText,
    updateFontSize,

    dragStamp,
    dragText,
    dragCheckbox,
    addVarFromDrag,

    onPageScaled,
    onPageSized,

    getFontSizeMobile,
  };
}
