
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import Popup from "@/components/popups/Popup.vue";
import { ContractRepository, RepositoryFactory } from "@/lib/https";
import { formatDate } from "@/lib/utility/common";
import { mapMutations, useStore } from "vuex";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  name: "ContractHistoryPopup",
  components: {
    Popup,
    Vue3Popper,
  },
  props: {
    contractTitle: { type: String, default: "", required: true },
    contractId: { type: String, default: "", required: true },
    isGuest: { type: Boolean, default: false },
  },
  setup(props) {
    const state = reactive({
      contractHistory: [] as never[],
    });
    const store = useStore();
    const isShow = computed(() => store.state.popup.ContractHistory);

    const { getContractHistory, getGuestContractHistory } =
      contractHistoryMethod();

    watch(
      () => isShow.value,
      async (val: any) => {
        if (val) {
          state.contractHistory = props.isGuest
            ? ((await getGuestContractHistory()) as never[])
            : ((await getContractHistory(props.contractId)) as never[]);
        }
      }
    );
    return { ...toRefs(state), isShow };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    formatDate(date: string) {
      return formatDate(date, "YYYY/MM/DD");
    },
  },
});

const contractHistoryMethod = () => {
  const { getContractHistory, getGuestContractHistory } =
    RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

  return { getContractHistory, getGuestContractHistory };
};
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
