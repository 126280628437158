import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "header-row" }
const _hoisted_3 = { class: "header__title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "notification-part"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag = _resolveComponent("tag")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", {
    class: "popup-wrapper",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clickOutPopup && _ctx.clickOutPopup(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["header", { 'header-divider': _ctx.hasHeaderDevider }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, () => [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
            (_ctx.tagText != '')
              ? (_openBlock(), _createBlock(_component_tag, {
                  key: 0,
                  text: _ctx.tagText,
                  class: "ml-3 header-tag"
                }, null, 8, ["text"]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.closeButton)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "cancel__icon",
              src: _ctx.closeIcon,
              alt: "close icon",
              onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMousedown && _ctx.onMousedown(...args))),
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hidePopup && _ctx.hidePopup(...args)), ["stop"]))
            }, null, 40, _hoisted_4))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.showInPopup)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_Notification, {
              class: _normalizeClass({ 'notification-mob': _ctx.isMobile })
            }, null, 8, ["class"])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}