
import { defineComponent } from "vue";
import Popper from "vue3-popper";
import "@/scss/_popper.scss";

export default defineComponent({
  name: "Vue3Popper",
  components: {
    Popper,
  },
  props: {
    content: { type: String, default: "" },
    isContract: { type: Boolean, default: false }
  },
});
