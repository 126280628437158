
import { defineComponent, inject } from "vue";
import NumberIcon from "@/components/atomics/NumberIcon.vue";
export default defineComponent({
  name: "SignStampField",
  components: {
    NumberIcon,
  },
  props: {
    order: { type: Number, default: 0 },
    field: { type: Object, default: {} as any },
    readonly: { type: Boolean, default: false },
    isPreview: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const isMobile = inject("isMobile");
    const onShowUploadStamp = (fieldId: string) => {
      emit("onShowUploadStamp", fieldId);
    };

    return { onShowUploadStamp, isMobile };
  },
});
