
import { defineComponent, onMounted, ref } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import NumberIcon from "@/components/atomics/NumberIcon.vue";
import useInputVars from "@/lib/compositional-logic/useInputVars";
import { computed } from "@vue/reactivity";
export default defineComponent({
  name: "SignTextField",
  components: {
    ValidationTextField,
    NumberIcon,
  },
  props: {
    order: { type: Number, default: 0 },
    field: { type: Object, default: {} as any },
    readonly: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const placeholder = ref(props.field?.text || "");

    const { getFontSizeMobile } = useInputVars();

    const fontSizeMob = computed(() =>
      getFontSizeMobile(props.field?.fontSize)
    );

    const onUpdate = (val: any) => {
      const payload = {
        value: val,
        fieldId: (props.field as any).id || "",
      };
      emit("onInputSignField", payload);
    };

    return { onUpdate, placeholder, fontSizeMob };
  },
});
