
import { defineComponent } from "vue";

export default defineComponent({
  name: "NumberIcon",
  props: {
    number: { type: Number, required: true },
    isCheck: { type: Boolean, default: false },
  },
});
