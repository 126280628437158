
import { computed, defineComponent, ref } from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { mapMutations, useStore } from "vuex";
import { useForm } from "vee-validate";
import { RepositoryFactory, SignRepository } from "@/lib/https";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "RequestCodePopup",
  components: {
    FlatButton,
    Popup,
  },
  props: {
    contractTitle: { type: String, default: "", required: true },
    contractId: { type: String, default: "", required: true },
    isGuest: { type: Boolean, default: false },
    is2FARequired: { type: Boolean, default: false },
    signedFields: { type: Array, default: [] as any },
  },
  setup(props) {
    const store = useStore();
    const isShow = computed(() => store.state.popup.RequestCode);
    const isLoading = ref(false);
    const router = useRouter();

    const { setFieldValue } = useForm();
    const { getAccessCode, getSignedContractVarsConclusion } =
      useSignFunctions();
    const {
      requestSignVerificationCode,
      requestSignGuest,
      signContract,
      signContractGuest,
    } = RepositoryFactory.getRepository<SignRepository>(SignRepository);

    const onRequestSign = async () => {
      const response = !props.isGuest
        ? await requestSignVerificationCode(props.contractId)
        : await requestSignGuest();

      if (response) {
        setFieldValue("signPasscode", "");
        store.commit("popup/setPopup", "SignCode");
        store.commit("popup/unsetPopup", "RequestCode");
      }
    };

    const onSign = async () => {
      const signedContractVarsConclusion = getSignedContractVarsConclusion(
        props.signedFields
      );

      const accessCode = getAccessCode(props.contractId);

      const signatureFieldAndAccessCode = {
        contractVars: signedContractVarsConclusion,
      };

      try {
        const response = !props.isGuest
          ? await signContract(
              props.contractId,
              accessCode,
              signatureFieldAndAccessCode
            )
          : await signContractGuest(accessCode, signatureFieldAndAccessCode);
        if (response) {
          navigateToSignDone();
        }
      } catch (e: any) {
        isLoading.value = false;
        if(e.data)
        store.commit("notification/showErrorNotification", e.data.message);
      } finally {
        store.commit("popup/unsetPopup", "RequestCode");
      }
    };

    const navigateToSignDone = () => {
      router.push({
        name: !props.isGuest ? "SignDone" : "GuestSignDone",
        query: {
          contractId: props.contractId,
        },
      });
    };

    return {
      isShow,
      isLoading,
      onRequestSign,
      onSign,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
