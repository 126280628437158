import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-340d3039"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "email-field"
}
const _hoisted_5 = { class: "error-message" }
const _hoisted_6 = {
  key: 1,
  class: "email-field"
}
const _hoisted_7 = { class: "input-label text-left" }
const _hoisted_8 = { class: "primary" }
const _hoisted_9 = { class: "input-row mt-2" }
const _hoisted_10 = { class: "input-label text-left" }
const _hoisted_11 = { class: "primary" }
const _hoisted_12 = { class: "name-input" }
const _hoisted_13 = { class: "input-label text-left mt-2" }
const _hoisted_14 = { class: "primary" }
const _hoisted_15 = { class: "input-row mt-2" }
const _hoisted_16 = { class: "input-area" }
const _hoisted_17 = { class: "input-label text-left" }
const _hoisted_18 = { class: "input-area" }
const _hoisted_19 = { class: "input-label text-left" }
const _hoisted_20 = { class: "w-full section-footer button-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!
  const _component_TransferUserSelect = _resolveComponent("TransferUserSelect")!
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_Popup = _resolveComponent("Popup")!
  const _component_ContractTransferConfirmPopup = _resolveComponent("ContractTransferConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.popupState)
      ? (_openBlock(), _createBlock(_component_Popup, {
          key: 0,
          title: _ctx.title,
          onOnClickOutsidePopup: _ctx.hidePopup,
          onOnHide: _ctx.hidePopup
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", {
                  class: "message",
                  innerHTML: _ctx.$t('contract.contractTransferMessage')
                }, null, 8, _hoisted_3),
                (!_ctx.isGuest)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_TransferUserSelect, {
                        items: _ctx.signers,
                        label: _ctx.$t('contract.contractTransferTableLabel'),
                        "search-hint": _ctx.$t('contract.contractTransferSearchHint'),
                        "limit-number": 1,
                        limit: true,
                        "is-required": true,
                        "is-show-company": true,
                        "email-error": _ctx.errorOfEmailTagField,
                        onOnUpdate: _ctx.updateSelectedSigner,
                        onOnInput: _ctx.signerSearch
                      }, {
                        default: _withCtx((slotProp) => [
                          (slotProp.error)
                            ? (_openBlock(), _createBlock(_component_MenuOptionDropdown, {
                                key: 0,
                                class: "error-tooltip"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_5, _toDisplayString(slotProp.error), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["items", "label", "search-hint", "email-error", "onOnUpdate", "onOnInput"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("contract.contractTransferTableLabel")), 1)
                      ]),
                      _createVNode(_component_validation_text_field, {
                        placeholder: _ctx.$t('common.email'),
                        "keep-error-space": false,
                        value: _ctx.guestEmail,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.guestEmail) = $event)),
                        name: "guestEmail",
                        rules: 'required|email',
                        message: {
              error_required: _ctx.$t('errors.required'),
              error_format_email: _ctx.$t('errors.formatEmail'),
            }
                      }, null, 8, ["placeholder", "value", "message"])
                    ])),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("sign.signerName")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_validation_text_field, {
                      placeholder: _ctx.$t('common.lastnameHint'),
                      class: "half-width",
                      "keep-error-space": false,
                      value: _ctx.lastName,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
                      name: "lastName",
                      rules: 'name|required',
                      message: {
                error_required: _ctx.$t('errors.required'),
                error_format_name: _ctx.$t('errors.formatName'),
              }
                    }, null, 8, ["placeholder", "value", "message"]),
                    _createVNode(_component_validation_text_field, {
                      placeholder: _ctx.$t('common.firstnameHint'),
                      class: "half-width",
                      "keep-error-space": false,
                      value: _ctx.firstName,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName) = $event)),
                      name: "firstName",
                      rules: 'name|required',
                      message: {
                error_required: _ctx.$t('errors.required'),
                error_format_name: _ctx.$t('errors.formatName'),
              }
                    }, null, 8, ["placeholder", "value", "message"])
                  ])
                ]),
                _createElementVNode("p", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("contract.contractTransferReason")), 1)
                ]),
                _createVNode(_component_validation_text_field, {
                  "is-multiline": true,
                  placeholder: _ctx.$t('contract.contractTransferReasonHint'),
                  "keep-error-space": false,
                  class: "multiline",
                  value: _ctx.transferReason,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.transferReason) = $event)),
                  name: "transferReason",
                  rules: "required",
                  message: {
            error_required: _ctx.$t('errors.required'),
          }
                }, null, 8, ["placeholder", "value", "message"]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("sign.optionalCompanyName")), 1),
                    _createVNode(_component_validation_text_field, {
                      placeholder: _ctx.$t('sign.companyName'),
                      class: "w-full",
                      "keep-error-space": false,
                      value: _ctx.companyName,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.companyName) = $event)),
                      name: "companyName"
                    }, null, 8, ["placeholder", "value"])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("sign.optionalAccessCode")), 1),
                    _createVNode(_component_validation_text_field, {
                      placeholder: '012345',
                      class: "w-full custom-input",
                      "suffix-icon": require('icons/key.svg'),
                      "keep-error-space": false,
                      value: _ctx.signerAccessCode,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.signerAccessCode) = $event)),
                      name: "signerAccessCode"
                    }, null, 8, ["suffix-icon", "value"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_flat_button, {
                onOnClick: _ctx.confirmTransferPopup,
                class: "confirm-btn m-auto",
                text: _ctx.$t('contract.transfer')
              }, null, 8, ["onOnClick", "text"])
            ])
          ]),
          _: 1
        }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ContractTransferConfirmPopup, {
      title: _ctx.$t('sign.transferSignature'),
      "signer-data": _ctx.selectedSigner,
      "on-confirm": _ctx.confirmTransfer
    }, null, 8, ["title", "signer-data", "on-confirm"])
  ], 64))
}