import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6706496"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "number-icon"
}
const _hoisted_2 = {
  key: 1,
  class: "check-icon"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isCheck)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.number), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("img", {
          src: require('icons/check-mark-green.svg')
        }, null, 8, _hoisted_3)
      ]))
}