
// @ is an alias to /sr
import { defineComponent, ref, inject, reactive, toRefs, onMounted } from "vue";
import Notification from "@/components/atomics/Notification.vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import FileTabs from "@/components/atomics/file-tabs/FileTabs.vue";
import FileTab from "@/components/atomics/file-tabs/FileTab.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import SideContractInfo from "@/components/organisms/SideContractInfo.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import ContractTransferPopup from "@/components/popups/contract-transfer/ContractTransferPopup.vue";
import ContractHistoryPopup from "@/components/popups/ContractHistoryPopup.vue";
import ContractStampPopup from "@/components/popups/contract-stamp/ContractStampPopup.vue";
import PdfPreviewVars from "@/components/parts/PdfPreviewVars.vue";

import SignTextField from "@/components/atomics/sign-fields/SignTextField.vue";
import SignStampField from "@/components/atomics/sign-fields/SignStampField.vue";
import SignCheckboxField from "@/components/atomics/sign-fields/SignCheckboxField.vue";

import XIDButton from "@/components/atomics/XIDButton.vue";

import RequestCodePopup from "@/components/popups/sign/RequestCodePopup.vue";
import SignCodePopup from "@/components/popups/sign/SignCodePopup.vue";
import RejectSignPopup from "@/components/popups/sign/RejectSignPopup.vue";

import RequestLoginPopup from "@/components/popups/contract-xID/RequestLoginPopup.vue";

import ContractSelectPopup from "@/components/popups/contract-xID/ContractSelectPopup.vue";
import ContractPinPopup from "@/components/popups/contract-xID/ContractPinPopup.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import {
  AccountRepository,
  AuthRepository,
  ContractRepository,
  RepositoryFactory,
  SignRepository,
} from "@/lib/https";
import { useRouter, useRoute } from "vue-router";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import SignEnum from "@/models/signEnum";

import { mapMutations, useStore } from "vuex";
import { isEmptyObject } from "@/lib/utility/common";
import { useI18n } from "vue-i18n";
import { storage } from "@/lib/storage";

import Vue3Popper from "@/components/atomics/Vue3Popper.vue";
import { isNeedToSign } from "@/lib/utility/permission";

export default defineComponent({
  name: "SignDetails",
  components: {
    TextButton,
    FlatButton,
    XIDButton,
    FileTabs,
    FileTab,
    MenuOptionPopup,
    SideContractInfo,
    HeaderTitleBar,
    Dropdown,
    PdfPreviewVars,
    ContractTransferPopup,
    ContractHistoryPopup,
    ContractStampPopup,
    ContractSelectPopup,
    ContractPinPopup,
    AfterLoginPageLayout,
    Notification,
    AvatarCircle,
    RequestLoginPopup,
    RequestCodePopup,
    SignCodePopup,
    RejectSignPopup,
    SignTextField,
    SignStampField,
    SignCheckboxField,
    Vue3Popper,
  },
  props: {
    contractId: { type: String, default: "" },
  },
  setup(props) {
    const isMobile = inject("isMobile");
    const store = useStore();
    const { t } = useI18n();
    const localStorage = storage.getLocalStorage();

    const state = reactive({
      showOptionType: 1,
      enableSign: false,
      contractState: {} as any,
      userProfile: {} as any,
      numberOfSigned: 0,
      tabFileNames: [] as any,
      accountOrder: 0,
      accountVars: [],
      otherSignerVars: [] as any,
      xidFileLists: [] as any,
      isMounted: false,
      needToSign: false,
      signingStamp: "",
    });

    const fileState = reactive({
      page: 1,
      numPages: 1,
      selectedFile: {} as any,
      selectedVarsToFill: [] as any,
    });

    const pinSignState = reactive({
      codeNumber: "",
      signFileId: "",
    });

    const showSuccessNotification = (text: string) =>
      store.commit("notification/showSuccessNotification", text);
    const showErrorNotification = (text: string) =>
      store.commit("notification/showErrorNotification", text);

    const router = useRouter();
    const route = useRoute();

    const {
      getContractFullInformation,
      getFullProfile,
      checkXIDInformation,
      loginXIDForSigning,
    } = contractDetailMethods();

    const {
      getNumberOfAlreadySignMember,
      getNeedToBeSignSignatureField,
      getAllFieldNeedToBeSignByUser,
      getOtherSignerVars,
      getFileMappingIdAndName,
      getSignerByEmail,
      haveBeenNotBeSigned,

      // signFileSuccess,
      getAccessCode,
      onInputField,
      saveVarFieldToLocalStorage,
    } = useSignFunctions();

    const checkXIDInfo = async () => {
      const response = await checkXIDInformation({});
      if (response.xidInfo === "ok") {
        if (state.xidFileLists.length === 0)
          state.xidFileLists = state.contractState.contractFiles.map(
            (file: any) => ({
              ...file,
              isSign: file.signed || false,
              contractVars: state.accountVars.filter(
                (field: any) => field.contractFileId === file.id
              ),
            })
          );
        showSelectFileXID();
      } else {
        store.commit("popup/setPopup", "RequestLoginXID");
      }
    };

    const showSelectFileXID = () =>
      store.commit("popup/setPopup", "SelectFileXID");
    const hideSelectFileXID = () =>
      store.commit("popup/unsetPopup", "SelectFileXID");

    const waitToSignFileByXID = ({ codeNumber = "", fileId = "" }) => {
      pinSignState.codeNumber = codeNumber;
      pinSignState.signFileId = fileId;
      hideSelectFileXID();
      store.commit("popup/setPopup", "SignPinXID");
    };

    const onRequestLoginXID = async () => {
      const response = await loginXIDForSigning(props.contractId);

      window.location.href = response.redirectUrl;
    };

    const onTransferContract = () => {
      store.commit("popup/unsetPopup", "ContractTransfer");
    };

    const navigateToSignDone = () => {
      router.push({
        name: "SignDone",
        query: {
          contractId: props.contractId,
        },
      });
    };

    //onBack
    const navigateToFolder = () => {
      const backEntry = {
        name: "Folder",
      } as any;

      backEntry.query = {
        folderId: state.contractState.folderId,
        folderTitle: state.contractState.folderTitle,
        status: route.params.status,
      };

      router.push(backEntry);
    };

    // prepare information functions

    const setGeneralInformation = async (contractInformation: any) => {
      state.contractState = contractInformation;
      state.needToSign = isNeedToSign(state.contractState.status);
      state.contractState.isSignerTransferAllowed =
        contractInformation.isSignerTransferAllowed ?? false;
      state.tabFileNames = getFileMappingIdAndName(
        contractInformation.contractFiles ?? []
      );
      const signer = getSignerByEmail(
        contractInformation.contractSigners ?? [],
        state.userProfile.email
      );
      if (signer) {
        state.accountOrder = (signer.order as number) ?? 0;
        state.userProfile = {
          ...state.userProfile,
          ...signer,
        };
        if (isAlreadySigned(state.userProfile.statusName)) navigateToSignDone();
      } else {
        if (contractInformation.status >= 3) navigateToSignDone();
        else navigateToReadonly(contractInformation.id);
      }
    };
    const isAlreadySigned = (statusName: string) =>
      statusName === "signed" || statusName === "rejected";
    const navigateToReadonly = (contractId: string) =>
      router.push({
        name: "ContractDetailReadonly",
        query: {
          contractId: contractId,
          isUser: "true",
        },
      });

    const setFieldOfSelectedFileNeedToBeSignByUser = (contractFile: any) => {
      fileState.selectedFile = contractFile;

      fileState.selectedVarsToFill = getNeedToBeSignSignatureField(
        !contractFile.signed ? contractFile.contractVars : [],
        state.accountOrder
      );
    };

    const isOnlyOptionalField = (fieldNeedToBeSigns: Array<any>) => {
      const fields = Object.values(fieldNeedToBeSigns);

      let isAnyRequiredField = true;
      fields.forEach((field: any) => {
        if (field.type != SignEnum.signatureFieldType.CHECKBOX_FIELD)
          isAnyRequiredField = false;
        if (!field.isOptionalCheckbox && !field.isChecked) {
          isAnyRequiredField = false;
        }
      });
      return isAnyRequiredField;
    };

    onMounted(async () => {
      state.isMounted = true;
      const isTransferSuccess =
        router.currentRoute.value.query.isTransferSuccess;
      if (isTransferSuccess) {
        showSuccessNotification(t("notification.forwardContract"));
      }
      const accessCode = getAccessCode(props.contractId);

      const payload = await getContractFullInformation(
        props.contractId,
        accessCode
      );

      //check the checkbox isChecked by default
      payload.contractFiles.forEach((file: any) => {
        const fileInputs = file.contractVars ?? [];
        file.contractVars = fileInputs.map((input: any) => {
          if (input.type === SignEnum.signatureFieldType.CHECKBOX_FIELD) {
            input.isChecked = input.checkedByDefault;
            input.isFilled = input.isChecked && !input.isOptionalCheckbox;
          }

          const extraBorder =
            input.type === SignEnum.signatureFieldType.STAMP_FIELD ? 1 : 0;

          input.x = input.positionX - extraBorder;
          input.y = input.positionY - extraBorder;
          input.w = input.width;
          input.h = input.height;
          return input;
        });
      });

      //get general information: isTransfer, order of signer
      state.userProfile = await getFullProfile();
      await setGeneralInformation(payload);

      //get fields of all file need to be signed
      state.accountVars = getAllFieldNeedToBeSignByUser(
        payload.contractFiles,
        state.accountOrder
      ) as never[];

      //loading old state of each var before page reloading

      const oldVarsInformationString = localStorage.get(
        state.contractState.id + "-vars"
      );
      if (oldVarsInformationString) {
        const oldVarsInformation = JSON.parse(oldVarsInformationString);
        state.accountVars.forEach((field: any, index: number) => {
          const item = oldVarsInformation.filter((e: any) => e.id === field.id);
          state.accountVars[index] = {
            ...field,
            ...item[0],
          } as never;
          state.enableSign = true;

          if (state.contractState.needToSignByXID) {
            checkXIDInfo();
          }
        });
      }

      const listKeysOfLocalStorage = localStorage.getListKey();
      const listPairContractIdVars = listKeysOfLocalStorage.filter(
        (key: string) => key.includes("-vars")
      );
      listPairContractIdVars.forEach((key: string) => {
        if (!key.includes(state.contractState.id)) localStorage.remove(key);
      });

      // get fields of default selected file need to be signed
      setFieldOfSelectedFileNeedToBeSignByUser(payload.contractFiles[0]);

      //check special case: not any field, only optional or only required but checked by default
      if (
        (isEmptyObject(state.accountVars) ||
          isOnlyOptionalField(state.accountVars ?? [])) &&
        state.accountOrder != 0 &&
        (state.userProfile.statusName === "unsigned" ||
          state.userProfile.statusName === "signing")
      ) {
        state.enableSign = true;
      }

      //get other's field
      state.otherSignerVars = getOtherSignerVars(
        payload.contractFiles,
        state.accountOrder
      );

      //count number of signed people
      state.numberOfSigned = getNumberOfAlreadySignMember(
        payload.contractSigners
      );

      // checked of required sign by XID -> if not link -> redirect
      if (payload.needToSignByXID) {
        const XIDLoginStatus = await checkXIDInformation({});
        if (XIDLoginStatus.xidInfo !== "ok") {
          if (
            router.currentRoute.value.query.xidInfo !== "ok" &&
            payload.needToSignByXID
          )
            store.commit("popup/setPopup", "RequestLoginXID");
        }
      }
    });

    return {
      ...toRefs(state),
      ...toRefs(fileState),
      ...toRefs(pinSignState),
      getFilePathFromUrl,
      isMobile,
      onTransferContract,
      checkXIDInfo,
      waitToSignFileByXID,
      onRequestLoginXID,
      haveBeenNotBeSigned,
      navigateToFolder,
      navigateToSignDone,
      showSelectFileXID,
      saveVarFieldToLocalStorage,
      onInputField,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    // general function
    download(url: string) {
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.click();
    },

    // Modified PDF view function
    onReady(file: any, pageNums: number) {
      this.numPages = pageNums;
      file.pageNums = pageNums;
    },
    onPageScaled(file: any, scale: any) {
      if (file.scale == 1 || !file.scale) {
        this.applyScale(file, scale);
      }

      if (file.scale && file.scale !== scale) {
        this.applyScale(file, scale);
      }
    },

    applyScale(file: any, scale: number) {
      file.scale = scale;
      this.accountVars.forEach((input: any) => {
        if (input.contractFileId === file.id && !input.isSaved) {
          input.width = input.w * scale;
          input.height = input.h * scale;
          input.positionX = input.x * scale;
          input.positionY = input.y * scale;
          input.scale = scale;
        }
      });
      this.otherSignerVars.forEach((input: any) => {
        if (input.contractFileId === file.id) {
          input.width = input.w * scale;
          input.height = input.h * scale;
          input.positionX = input.x * scale;
          input.positionY = input.y * scale;
        }
      });
    },

    increasePage() {
      if (this.page < this.numPages) {
        this.page = this.page + 1;
      }
    },
    decreasePage() {
      if (this.page > 1) {
        this.page = this.page - 1;
      }
    },
    setPage(e: any) {
      if (!this.page && !this.selectedFile.pageNums) {
        this.selectedFile.currentPage = 1;
        return;
      }
      const page = parseInt(e.target.value, 10);
      if (page <= 0) {
        this.page = 1;
        e.target.value = 1;
        return;
      } else if (page >= (this.selectedFile.pageNums || 0)) {
        this.page = this.selectedFile.pageNums;
        e.target.value = this.selectedFile.pageNums;
        return;
      } else if (page) this.page = page;
      if (e.data != null) {
        this.page = parseInt(e.data);
      }
    },
    onChangeTab(value: number) {
      if (this.contractState.contractFiles) {
        this.selectedFile = this.contractState.contractFiles[value];
        this.page = 1;
        if (this.selectedFile.contractVars) {
          this.selectedVarsToFill =
            this.selectedFile?.contractVars.filter(
              (item: any) => item.contractSignerOrder === this.accountOrder
            ) || [];
        } else {
          this.selectedVarsToFill.length = 0;
        }
      }
    },
    onChangeDropdown(val: any) {
      if (this.contractState.contractFiles) {
        const index = this.contractState.contractFiles.findIndex(
          (file: any) => file.id === val
        );

        if (index >= 0) this.onChangeTab(index);
      }
    },

    // signature field modified function

    onInputSignField(val: any, fieldId: string) {
      this.onInputField(val, fieldId, this.accountVars, this.isEnableSign);
    },

    onShowUploadStamp(fieldId: string) {
      const field = this.accountVars.find((item: any) => item.id === fieldId);
      this.signingStamp = fieldId;

      if (field) {
        this.setPopup("ContractStamp");
      }
    },
    onUploadImage(val: any) {
      const stampFields = this.accountVars.filter(
        (field: any) => field.type === SignEnum.signatureFieldType.STAMP_FIELD
      );

      const page = document.getElementById("canvas-overlay");
      const pageSize = page?.getBoundingClientRect();

      if (!pageSize) {
        this.unsetPopup("ContractStamp");
        return;
      }

      const field = stampFields.find(
        (item: any) => item.id === this.signingStamp
      ) as any;

      if (!field) return;

      field.text = val.text;
      field.base64 = val.base64;
      field.isImprint = val.isImprint ?? false;
      field.isFilled = true;

      const newWidth = ((val.size * 41) / 15) * field.scale;
      const paddingLeft = 10 * field.scale + newWidth / 2;
      const paddingTop = 10 * field.scale;

      field.imprintType = val.imprintType;
      field.positionX =
        field.scale * field.x + (field.scale * field.w - newWidth) / 2 + 1;
      field.positionY =
        field.scale * field.y + (field.scale * field.h - newWidth) / 2 + 1;

      if (field.positionX + paddingLeft + newWidth > pageSize.width)
        field.positionX = pageSize.width - newWidth - paddingLeft;
      if (field.positionX - paddingLeft < 0) field.positionX = paddingLeft;

      if (field.positionY + paddingTop + newWidth > pageSize.height)
        field.positionY = pageSize.height - newWidth - paddingTop;
      if (field.positionY - paddingTop < 0) field.positionY = paddingTop;

      field.height = newWidth;
      field.width = newWidth;
      field.scale = field.scale || 1;

      this.isEnableSign(this.accountVars);
      this.unsetPopup("ContractStamp");
    },

    // enable/disable sign button validate function

    isEnableSign(fields: any) {
      this.enableSign = true;
      fields.forEach((field: any) => {
        if (this.haveBeenNotBeSigned(field)) this.enableSign = false;
      });
      if (this.enableSign)
        this.saveVarFieldToLocalStorage(fields, this.contractState.id);
    },

    // XID Sign Popup Function

    signFileSuccess(fileId: string) {
      const file =
        this.xidFileLists.find((item: any) => item.id === fileId) || {};

      file.isSign = true;
      file.signed = true;
      const isContinueSigning =
        this.xidFileLists.filter((file: any) => file.isSign === false)
          .length !== 0;
      if (!isContinueSigning) this.navigateToSignDone();
    },

    // Show MenuOption Function

    viewHistory() {
      this.setPopup("ContractHistory");
    },

    showSignerList() {
      this.showOptionType = 3;
    },

    showViewerList(index: number) {
      this.showOptionType = 2;
    },

    backToFileViewerOption() {
      this.showOptionType = 1;
    },

    viewTransfer() {
      this.setPopup("ContractTransfer");
    },
  },
  beforeRouteLeave(to, from, next) {
    this.unsetPopup("RequestLoginXID");
    this.unsetPopup("SelectFileXID");
    this.unsetPopup("SignPinXID");

    next();
  },
});

const contractDetailMethods = () => {
  const { getContractFullInformation } =
    RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

  const { getFullProfile } =
    RepositoryFactory.getRepository<AccountRepository>(AccountRepository);

  const { signFile, checkSignatureStatus } =
    RepositoryFactory.getRepository<SignRepository>(SignRepository);

  const { checkXIDInformation, loginXIDForSigning } =
    RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

  return {
    getContractFullInformation,
    getFullProfile,
    checkXIDInformation,
    signFile,
    checkSignatureStatus,
    loginXIDForSigning,
  };
};
