import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b725b5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "body-container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "stamp-area"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "stamp-area"
}
const _hoisted_7 = { class: "stamp-wapper" }
const _hoisted_8 = { class: "imprint-setting-area" }
const _hoisted_9 = { class: "shape-setting" }
const _hoisted_10 = {
  key: 0,
  class: "shape-text"
}
const _hoisted_11 = {
  key: 1,
  class: "shape-text"
}
const _hoisted_12 = {
  key: 2,
  class: "imprint-setting-area"
}
const _hoisted_13 = { class: "shape-setting" }
const _hoisted_14 = {
  key: 0,
  class: "shape-text"
}
const _hoisted_15 = {
  key: 1,
  class: "shape-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleCheckbox = _resolveComponent("ToggleCheckbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_Popup, {
        key: 0,
        title: 
      !_ctx.isOffical
        ? _ctx.$t('sign.signOrginalStampTitle')
        : _ctx.$t('sign.uploadStampTitle')
    ,
        onOnClickOutsidePopup: _ctx.hide,
        onOnHide: _ctx.hide
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", {
                innerHTML: !_ctx.isOffical ? _ctx.$t('sign.stampMessageOriginal') : _ctx.$t('sign.stampMessageOfficial'),
                class: "message"
              }, null, 8, _hoisted_3),
              (_ctx.stampImage != '' && !_ctx.isOffical)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["circle m-auto", { square: !_ctx.isRound }])
                    }, [
                      _createElementVNode("img", {
                        src: _ctx.stampImage,
                        alt: "stamp image",
                        id: "logo_stamp"
                      }, null, 8, _hoisted_5)
                    ], 2)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["stamp", { square: !_ctx.isRound }]),
                        id: "logo_stamp"
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass({
                  'long-text': _ctx.textCompanyLogo && _ctx.textCompanyLogo.length > 19,
                })
                        }, _toDisplayString(_ctx.textCompanyLogo != ""
                    ? _ctx.textCompanyLogo.length > 19
                      ? _ctx.textCompanyLogo.substring(0, 18).concat("...")
                      : _ctx.textCompanyLogo
                    : _ctx.$t("sign.companyNameHint")), 3)
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_ToggleCheckbox, {
                          checked: _ctx.isRound,
                          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isRound) = $event)),
                          disabled: !_ctx.isOffical
                        }, null, 8, ["checked", "disabled"]),
                        (!_ctx.isRound)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t("common.square")), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t("common.circle")), 1))
                      ]),
                      _createVNode(_component_Dropdown, {
                        class: "size-dropdown",
                        options: _ctx.sizeOptions,
                        "default-option": _ctx.selectedSize,
                        onOnChange: _ctx.updateSize
                      }, null, 8, ["options", "default-option", "onOnChange"])
                    ]),
                    _createVNode(_component_validation_text_field, {
                      class: "stamp-field",
                      placeholder: _ctx.$t('sign.companyNameHint'),
                      "keep-error-space": false,
                      value: _ctx.company,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.company) = $event)),
                      onInput: _ctx.changeTextCompany,
                      rules: "required",
                      message: {
              error_required: _ctx.$t('errors.required'),
            },
                      name: "companyName"
                    }, null, 8, ["placeholder", "value", "onInput", "message"])
                  ])),
              (_ctx.stampImage != '' && !_ctx.isOffical)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_ToggleCheckbox, {
                        checked: _ctx.isRound,
                        "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isRound) = $event)),
                        disabled: !_ctx.isOffical
                      }, null, 8, ["checked", "disabled"]),
                      (!_ctx.isRound)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.$t("common.square")), 1))
                        : (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.$t("common.circle")), 1))
                    ]),
                    _createVNode(_component_Dropdown, {
                      class: "size-dropdown",
                      options: _ctx.sizeOptions,
                      "default-option": _ctx.selectedSize,
                      readonly: !_ctx.isOffical,
                      onOnChange: _ctx.updateSize
                    }, null, 8, ["options", "default-option", "readonly", "onOnChange"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["btn-area mt-2", { 'mt-5': _ctx.stampImage != '' && !_ctx.isOffical }])
              }, [
                _createVNode(_component_TextButton, {
                  onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOffical = !_ctx.isOffical)),
                  text: 
              !_ctx.isOffical
                ? _ctx.$t('sign.useOfficalImprint')
                : _ctx.$t('sign.useOriginalImprint')
            ,
                  enabled: _ctx.stampImage != ''
                }, null, 8, ["text", "enabled"]),
                _createVNode(_component_flat_button, {
                  onOnClick: _ctx.onConfirm,
                  class: "custom-btn w-full mt-2",
                  text: _ctx.$t('sign.useStampText')
                }, null, 8, ["onOnClick", "text"])
              ], 2)
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}