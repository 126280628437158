
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  onMounted,
} from "vue";
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { getImageFromHtmlElement } from "@/lib/utility/common";
import { useStore } from "vuex";
import { CompanyRepository, RepositoryFactory } from "@/lib/https";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";

export default defineComponent({
  name: "ContractStampPopup",
  components: {
    Popup,
    FlatButton,
    TextButton,
    ValidationTextField,
    Dropdown,
    ToggleCheckbox,
  },
  props: {
    isGuest: { type: Boolean, default: false },
  },
  setup(props) {
    const state = reactive({
      company: "",
      textCompanyLogo: "",
      isLongText: false,
      isOffical: true,
      isRound: true,
      selectedSize: "21",
      stampImage: "",
      sizeOptions: [
        { id: "15", name: "15mm" },
        { id: "18", name: "18mm" },
        { id: "21", name: "21mm" },
        { id: "24", name: "24mm" },
      ],
      companyInformation: {} as any,
    });
    const store = useStore();
    const isShow = computed(() => store.state.popup.ContractStamp);

    const hide = () => {
      store.commit("popup/unsetPopup", "ContractStamp");
    };

    const updateSize = (id: string) => {
      state.selectedSize =
        state.sizeOptions.find((item: any) => item.id === id)?.id || "";
    };

    const { getCompany } = RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    watch(
      () => state.isOffical,
      (val) => {
        if (val) {
          state.isRound = true;
          state.selectedSize = "21";
        } else {
          state.isRound = state.companyInformation.imprintType === 1;
          state.selectedSize = state.companyInformation.imprintSize ? state.companyInformation.imprintSize.toString() : "21";
        }
      }
    );

    onMounted(async () => {
      if (!props.isGuest) {
        state.companyInformation = await getCompany();
        state.company = state.companyInformation.name;
        state.textCompanyLogo = state.companyInformation.name;
        state.stampImage = state.companyInformation.imprint ? getFilePathFromUrl(state.companyInformation.imprint) : '';
        state.isOffical = !state.companyInformation.imprint ? true : false;
        state.isRound = state.companyInformation.imprintType === 1;
        state.selectedSize = state.companyInformation.imprintSize ? state.companyInformation.imprintSize.toString() : "21";
      }
    });

    return { ...toRefs(state), isShow, hide, updateSize };
  },
  methods: {
    changeTextCompany() {
      if (this.company.length > 20) {
        this.textCompanyLogo = this.company.substring(0, 18) + "...";
      } else {
        this.textCompanyLogo = this.company;
      }
      if (this.company.length > 16) {
        this.isLongText = true;
      } else {
        this.isLongText = false;
      }
    },
    async getImage() {
      var node = document.getElementById("logo_stamp") as HTMLElement;
      const img = await getImageFromHtmlElement(node);

      return img;
    },

    async onConfirm() {
      if (this.company.trim() == "" && !this.stampImage) return;
      let stamp = {};
      if (this.stampImage === "" || this.isOffical) {
        // const img = await this.getImage();
        stamp = {
          base64: "",
          size: Number.parseInt(this.selectedSize),
          imprintType: this.isRound ? 1 : 2,
          text: this.company,
          isImprint: false,
        };
      } else {
        stamp = {
          base64: this.stampImage,
          size: Number.parseInt(this.selectedSize),
          imprintType: this.isRound ? 1 : 2,
          text: "",
          isImprint: true,
        };
      }
      this.$emit("onConfirm", stamp);
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
