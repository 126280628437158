import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "preview-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfViewer = _resolveComponent("PdfViewer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pdf-preview-var", { 'pt-2 pl-2 pb-2 pr-2': _ctx.isPadding }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isPdf)
        ? (_openBlock(), _createBlock(_component_PdfViewer, {
            key: 0,
            class: "preview-page",
            src: _ctx.filePath,
            page: _ctx.page,
            "scale-fit-width": _ctx.scaleFitWidth,
            onPageNums: _ctx.onPageReady,
            onPageLoaded: _ctx.pageLoaded,
            onPageScaled: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('pageScaled', $event))),
            onPageSized: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('pageSized', $event)))
          }, {
            default: _withCtx(() => [
              (_ctx.pageReady)
                ? _renderSlot(_ctx.$slots, "default", { key: 0 })
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["src", "page", "scale-fit-width", "onPageNums", "onPageLoaded"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}