import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f5323e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contract-popup" }
const _hoisted_2 = { class: "contract-message mt-5" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "confirm-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.$t('sign.rejectPopupTitle'),
        onOnClickOutsidePopup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoading ? null : _ctx.unsetPopup('RejectSign'))),
        onOnHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLoading ? null : _ctx.unsetPopup('RejectSign'))),
        class: "contract-popup"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", {
                innerHTML: _ctx.$t('sign.rejectPopupMessage'),
                class: "message"
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_FlatButton, {
                onOnLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoading = $event)),
                "on-click": _ctx.onReject,
                text: _ctx.$t('sign.reject')
              }, null, 8, ["on-click", "text"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}