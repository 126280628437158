
import { computed, defineComponent } from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { useStore } from "vuex";
import { AuthRepository, RepositoryFactory } from "@/lib/https";

export default defineComponent({
  name: "RequestLoginPopup",
  components: {
    FlatButton,
    Popup,
  },
  props: {
    contractId: { type: String, default: "" },
    isGuest: { type: Boolean, default: false },
  },
  emits: ["onHide", "onSelect"],
  setup(props, { emit }) {
    const store = useStore();
    const isShow = computed(() => store.state.popup.RequestLoginXID);

    const { loginXIDForSigning, loginXIDGuestForSigning } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

    const hide = () => {
      store.commit("popup/unsetPopup", "RequestLoginXID");
    };

    const onRequestLoginXID = async () => {
      const response = !props.isGuest
        ? await loginXIDForSigning(props.contractId)
        : await loginXIDGuestForSigning();
      window.location.href = response.redirectUrl;
    };

    return {
      isShow,
      hide,
      onRequestLoginXID,
    };
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
