
import {
  defineComponent,
  reactive,
  provide,
  onBeforeMount,
  onMounted,
  toRefs,
  VNode,
  ref,
  watch,
} from "vue";

interface FileTabProps {
  name: string;
}

export default defineComponent({
  name: "FileTabs",
  props: {
    isBorder: { type: Boolean, default: true },
    tabName: { type: Array },
    isTerms: { type: Boolean, default: false },
    teleportTabsHeaderTo: { type: String, default: "body" },
  },
  emits: ["onChangeTab"],
  setup(props, { slots, emit }) {
    const state = reactive({
      selectedIndex: 0,
      tabs: [] as VNode<FileTabProps>[],
      count: 0,
    });
    const names = ref([]);

    provide("TabsProvider", state);

    const selectTab = (i: number) => {
      if (state.selectedIndex == i) return;
      state.selectedIndex = i;
      emit("onChangeTab", i);
    };

    onBeforeMount(() => {
      if (slots.default) {
        let childTabs = (slots.default()[0]?.children as any[]) || [];
        state.tabs = childTabs.filter(
          (child: any) => child.type.name === "FileTab"
        ) as VNode<FileTabProps>[];
      }
    });

    watch(
      () => props.tabName,
      (val: any) => {
        names.value = val;
      }
    );

    onMounted(() => {
      if (props.tabName) {
        names.value = props.tabName as never[];
      } else {
        state.tabs.forEach((tab: any) => {
          names.value.push({
            name: tab.props.name,
          } as never);
        });
      }
      if(names.value.length > 0) 
        selectTab(0);
    });

    return { ...toRefs(state), selectTab, names };
  },
});
