
import { defineComponent, reactive, toRefs, ref, computed, watch } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TransferUserSelect from "@/components/atomics/TransferUserSelect.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import closeIcon from "@/assets/icons/close.svg";
import Popup from "@/components/popups/Popup.vue";
import ContractTransferConfirmPopup from "@/components/popups/contract-transfer/ContractTransferConfirmPopup.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { TransferRepository, RepositoryFactory } from "@/lib/https";
import { isEmptyObject } from "@/lib/utility/common";
import { useRoute, useRouter } from "vue-router";
import storage from "@/lib/storage/storageHelper";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { mapMutations, useStore } from "vuex";
import router from "@/router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ContractTransferPopup",
  components: {
    FlatButton,
    TransferUserSelect,
    ValidationTextField,
    Popup,
    ContractTransferConfirmPopup,
    MenuOptionDropdown,
  },
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
    contractId: { type: String, required: true },
    isGuest: { type: Boolean, default: false },
  },
  emits: ["onConfirm", "onSubmitTransferContract"],
  setup(props, context) {
    const store = useStore();
    const { t } = useI18n();
    const state = reactive({
      closeIcon: closeIcon,
      showContractTransferConfirm: false,
      selectedSigner: {},
      selectedSignerId: "",
      selectedSignerEmail: "",
      transferReason: "",
      signerAccessCode: "",
      companyName: "",
      firstName: "",
      lastName: "",
      guestEmail: "",
      errorOfEmailTagField: "",
    });

    const signers = ref();
    const searchKeyword = ref("");
    let delay: ReturnType<typeof setTimeout>;

    const transferData = ref();
    const router = useRouter();
    const localStorage = storage.getLocalStorage();

    const { searchNewSignerToTransfer, guestTransferSigners, transferSigners } =
      RepositoryFactory.getRepository<TransferRepository>(TransferRepository);

    watch(
      () => [props.contractId, store.state.popup.ContractTransfer],
      async () => {
        if (props.contractId) {
          if (props.isGuest) return;
          const signerPayload = await searchNewSignerToTransfer(
            searchKeyword.value,
            props.contractId
          );
          if (signerPayload) {
            signers.value = signerPayload.data;
          }
        }
      },
      { immediate: true }
    );

    // Table select search signers
    const signerSearch = (searchKeyword: string) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const signerPayload = await searchNewSignerToTransfer(
          searchKeyword,
          props.contractId
        );
        signers.value = signerPayload.data;
      }, 300);
    };

    const confirmTransfer = async () => {
      (state.selectedSigner as any).firstName = state.firstName;
      (state.selectedSigner as any).lastName = state.lastName;
      if (props.isGuest) await confirmGuestTransfer();
      else await confirmSignerTransfer();
    };

    const confirmGuestTransfer = async () => {
      transferData.value = {
        id: "",
        emailTransferTo: state.guestEmail,
        transferReason: state.transferReason,
        signerLastName: state.lastName,
        signerFirstName: state.firstName,
        signerCompanyName: state.companyName,
        signerAccessCode: state.signerAccessCode,
      };
      await submitGuestTransfer();
    };

    const confirmSignerTransfer = async () => {
      transferData.value = {
        id: props.contractId,
        signerLastName: state.lastName,
        signerFirstName: state.firstName,
        // userIdTransferTo: state.selectedSignerId,
        emailTransferTo: state.selectedSignerEmail,
        transferReason: state.transferReason,
        signerCompanyName: state.companyName,
        signerAccessCode: state.signerAccessCode,
      };
      await submitSignerTransfer(props, context, t("notification.updateSuccess"));
    };

    const formSuccess = () => {
      store.commit("popup/unsetPopup", "ContractTransferConfirm");
      resetPopup();

      if (props.isGuest) {
        const guestCode = localStorage.get("guestCode");
        const decode = Buffer.from(guestCode, "base64").toString();

        const user = JSON.parse(decode);
        router.push({
          name: "GuestTransferDone",
          params: { lastName: state.lastName, firstName: state.firstName },
        });
      }
    };

    const { submitGuestTransfer } = formHandler(
      transferData,
      guestTransferSigners,
      formSuccess
    );

    const { submitSignerTransfer } = formHandler(
      transferData,
      transferSigners,
      formSuccess
    );

    const form = useForm();
    const { formHandle } = useFormHandler(form, async () => {
      if (!props.isGuest && isEmptyObject(state.selectedSigner)) {
        // Error: Add one transfer's user before move on confirmation
        state.errorOfEmailTagField = t("contract.contractTransferTagError2");
      } else {
        (state.selectedSigner as any).firstName = state.firstName;
        (state.selectedSigner as any).lastName = state.lastName;
        store.commit("popup/setPopup", "ContractTransferConfirm");
        context.emit("onConfirm");
      }
    });

    const confirmTransferPopup = async function () {
      await formHandle();
    };

    const resetPopup = () => {
      state.selectedSigner = {};
      state.selectedSignerId = "";
      state.transferReason = "";
      state.signerAccessCode = "";
      state.companyName = "";
      state.firstName = "";
      state.lastName = "";
      state.guestEmail = "";
      state.errorOfEmailTagField = "";
    };

    return {
      ...toRefs(state),
      popupState: computed(() => store.state.popup.ContractTransfer),
      signers,
      searchKeyword,
      signerSearch,
      confirmTransferPopup,
      confirmTransfer,
      resetPopup,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    hidePopup(): void {
      this.unsetPopup("ContractTransfer");
      this.resetPopup();
    },

    updateSelectedSigner(payload: any) {
      this.selectedSigner = payload[0];
      this.selectedSignerId = payload[0]?.id;
      this.selectedSignerEmail = payload[0]?.email;
      this.firstName = payload[0]?.firstName;
      this.lastName = payload[0]?.lastName;
      this.companyName = payload[0]?.companyName;
    },
  },
});

// Transfer signer
const formHandler = (
  transferData: any,
  onSubmit: CallableFunction,
  onSuccess: CallableFunction
) => {
  const { values, errors, submitForm } = useForm();

  const submitGuestTransfer = async () => {
    await submitForm();
    if (await onSubmit(transferData.value)) {
      await onSuccess();
    }
  };

  const submitSignerTransfer = async (props: any, context: any, successMessage: string) => {
    await submitForm();
    if (await onSubmit(transferData.value)) {
      await onSuccess();
      if (router.currentRoute.value.name === "Folder") {
        context.emit("onSubmitTransferContract");
      } else if (router.currentRoute.value.name === "SignDetails") {
        router.push({
          name: "Folder",
          params: {
            withSuccess: successMessage
          },
        });
      }
    }
  };

  return { submitGuestTransfer, submitSignerTransfer };
};
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
