import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92606958"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "body-container" }
const _hoisted_3 = { class: "contract-list w-full" }
const _hoisted_4 = { class: "contract-item w-full" }
const _hoisted_5 = { class: "item-leading" }
const _hoisted_6 = { class: "icon-wrapper mr-2" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "contract-name" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "thin-divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.contractTitle + _ctx.$t('contractGuest.signingContract'),
        onOnClickOutsidePopup: _ctx.hide,
        onOnHide: _ctx.hide
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contracts, (contract, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "w-full"
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("img", {
                            src: require('icons/report_orange.svg'),
                            alt: "template icon"
                          }, null, 8, _hoisted_7)
                        ]),
                        _createElementVNode("p", _hoisted_8, _toDisplayString(contract.fileName), 1)
                      ]),
                      (!contract.isSign)
                        ? (_openBlock(), _createBlock(_component_flat_button, {
                            key: 0,
                            onOnClick: ($event: any) => (_ctx.select(contract)),
                            text: _ctx.$t('folder.sign'),
                            class: "sign-btn"
                          }, null, 8, ["onOnClick", "text"]))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: require('icons/success-green.svg'),
                            alt: "check icon"
                          }, null, 8, _hoisted_9))
                    ]),
                    (index != _ctx.contracts.length - 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}