
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";

export default defineComponent({
  name: "PdfPreviewVars",
  components: {
    PdfViewer,
  },
  props: {
    filePath: { type: String, default: "" },
    fileType: { type: String, default: "" },
    fileName: { type: String, default: "" },
    page: { type: Number, default: 1 },
    isPadding: { type: Boolean, default: false },
    scaleFitWidth: { type: Boolean, default: true },
  },
  emit: ["update:page", "onReady", "pageLoaded"],
  setup(props) {
    const state = reactive({
      numPages: 1,
      pageReady: false as boolean,
    });

    const isPdf = computed(() => {
      return props.fileType.indexOf("pdf") >= 0;
    });

    return {
      ...toRefs(state),
      isPdf,
    };
  },
  methods: {
    pageLoaded(page: number) {
      this.$emit("update:page", page);
      this.$emit("pageLoaded");
    },

    onPageReady(numPages: number) {
      this.numPages = numPages;
      this.pageReady = true;
      this.$emit("onReady", this.numPages);
    },

    setPage(p: number) {
      this.pageReady = false;
      if (p <= 0) {
        this.$emit("update:page", 1);
      } else if (p > this.numPages) {
        this.$emit("update:page", this.numPages);
      } else {
        this.$emit("update:page", p);
      }
    },
  },
});
