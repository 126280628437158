
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { useStore } from "vuex";
import { RepositoryFactory, SignRepository } from "@/lib/https";
import SignEnum from "@/models/signEnum";

export default defineComponent({
  name: "ContractPinPopup",
  components: {
    FlatButton,
    Popup,
  },
  props: {
    contractId: { type: String, default: "" },
    codeNumber: { type: String, default: "" },
    fileId: { type: String, default: "" },
    isGuest: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const state = reactive({
      pinCode: [],
      isLoading: false,
      isSuccess: false,
      isError: false,
      delay: {} as ReturnType<typeof setInterval>,
    });
    const store = useStore();
    const isShow = computed(() => store.state.popup.SignPinXID);

    const { checkSignatureStatus, checkSignatureGuestStatus } =
      RepositoryFactory.getRepository<SignRepository>(SignRepository);

    const onSuccess = () => {
      onDone();
      emit("onSuccess", props.fileId);
    };

    const onDone = () => {
      hide();
      store.commit("popup/setPopup", "SelectFileXID");
    };

    const hide = () => {
      resetState();
      store.commit("popup/unsetPopup", "SignPinXID");
    };

    const onClose = () => {
      if (state.isLoading) return;
      else if(state.isSuccess) {
        onSuccess();
        return;
      }
      else if(state.isError){
        onDone();
        return;
      }
      else 
        hide();
    };

    const resetState = () => {
      if (state.delay) clearInterval(state.delay);
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    };

    watch(
      () => isShow.value,
      (val) => {
        if (val) checkStatus();
      }
    );

    watch(
      () => props.codeNumber,
      (val) => (state.pinCode = val.split("") as never[])
    );

    const checkStatus = async () => {
      state.delay = setInterval(async () => {
        const response = !props.isGuest
          ? await checkSignatureStatus(props.contractId, props.fileId)
          : await checkSignatureGuestStatus(props.fileId);
        processingSignStatus(response.signatureStatus ?? "");
      }, 1000);
    };

    const processingSignStatus = (status: string) => {
      switch (status) {
        case SignEnum.signXIDStatus.CLAIMED: {
          state.isLoading = false;
          state.isSuccess = false;
          state.isError = false;
          break;
        }
        case SignEnum.signXIDStatus.CODE_SUCCEED:
          state.isLoading = true;
          break;
        case SignEnum.signXIDStatus.SIGNED: {
          state.isLoading = false;
          state.isSuccess = true;
          clearInterval(state.delay);
          break;
        }
        default: {
          state.isLoading = false;
          state.isError = true;
          clearInterval(state.delay);
          break;
        }
      }
    };

    onMounted(() => {
      state.pinCode = props.codeNumber.split("") as never[];
    });

    return { ...toRefs(state), isShow, hide, onDone, onSuccess, onClose };
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
