import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "side-info" }
const _hoisted_2 = {
  key: 0,
  class: "contract-name"
}
const _hoisted_3 = {
  key: 1,
  class: "thin-divider"
}
const _hoisted_4 = { class: "component-list mt-1" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "detail" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberIcon = _resolveComponent("NumberIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.contractName), 1))
      : _createCommentVNode("", true),
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          (component.isFilled)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require('icons/check-mark-green.svg')
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createBlock(_component_NumberIcon, {
                key: 1,
                number: index + 1
              }, null, 8, ["number"])),
          _createElementVNode("div", _hoisted_6, [
            (component.type == 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("sign.objectText")), 1)
                ]))
              : (component.type == 2)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("sign.objectStamp")), 1)
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_9, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("sign.objectCheck")), 1)
                  ]))
          ])
        ]))
      }), 128))
    ])
  ]))
}