
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";

import closeIcon from "@/assets/icons/close.svg";
import { useI18n } from "vue-i18n";
import { mapMutations, useStore } from "vuex";
import Popup from "@/components/popups/Popup.vue";

export default defineComponent({
  name: "ContractTransferConfirmPopup",
  components: {
    Popup,
    FlatButton,
  },
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
    signerData: { type: Object, required: true },
    onConfirm: { type: Function, required: true },
  },
  setup(props, context) {
    const store = useStore();
    const { t } = useI18n();
    const state = reactive({
      closeIcon: closeIcon,
      isLoading: false,
    });

    const message = computed(
      () =>
        t("contract.contractTransferConfirm") +
        "<span style='color: #17B182'><strong>" +
        props.signerData.lastName +
        " " +
        props.signerData.firstName +
        t("contract.contractTransferConfirmMessage")
    );

    const confirmTransfer = async () => {
      props?.onConfirm && await props.onConfirm();
    };

    return {
      ...toRefs(state),
      popupState: computed(() => store.state.popup.ContractTransferConfirm),
      message,
      confirmTransfer,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    hidePopup(): void {
      this.unsetPopup("ContractTransferConfirm");
      this.setPopup("ContractTransfer");
    },
  },
});

/**
 * @vuese
 * @group Layout
 * Popup layout
 */
