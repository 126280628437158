
import { computed, defineComponent, ref } from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { mapMutations, useStore } from "vuex";
import { RepositoryFactory, SignRepository } from "@/lib/https";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "RejectSignPopup",
  components: {
    FlatButton,
    Popup,
  },
  props: {
    contractId: { type: String, default: "", required: true },
    folderId: { type: String, default: "" },
    isGuest: { type: Boolean, default: false },
    isRejectFromFolder: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const isShow = computed(() => store.state.popup.RejectSign);
    const isLoading = ref(false);

    const { rejectSign, rejectSignGuest } =
      RepositoryFactory.getRepository<SignRepository>(SignRepository);

    const onReject = async () => {
      try {
        const response = !props.isGuest
          ? await rejectSign(props.contractId)
          : await rejectSignGuest();

        if (response) {
          store.commit("popup/unsetPopup", "RejectSign");
          if (props.isRejectFromFolder) {
            emit("onRejectContract");
          } else {
            if (props.isGuest) {
              router.push({
                name: "GuestSignDone",
                query: {
                  contractId: props.contractId,
                },
              });
            } else
              router.push({
                name: "Folder",
                query: {
                  folderId: props.folderId,
                },
                params: {
                  withSuccess: t("notification.updateSuccess")
                },
              });
          }
        }
      } catch (err) {
        store.commit("popup/unsetPopup", "RejectSign");
        emit("onRejectContract", err);
      }
    };

    return {
      isShow,
      isLoading,
      onReject,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
