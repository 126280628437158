import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35604892"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "body-container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "w-full mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_ctx.popupState)
    ? (_openBlock(), _createBlock(_component_Popup, {
        key: 0,
        title: _ctx.title,
        onOnClickOutsidePopup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoading ? null : _ctx.hidePopup())),
        onOnHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLoading ? null : _ctx.hidePopup()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", {
                innerHTML: _ctx.message,
                class: "message"
              }, null, 8, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_FlatButton, {
                  "on-click": _ctx.confirmTransfer,
                  onOnLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoading = $event)),
                  text: _ctx.$t('contract.transfer'),
                  class: "custom-button"
                }, null, 8, ["on-click", "text"])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}