import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49a25276"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header-optional"
}
const _hoisted_2 = { class: "body-checkbox" }
const _hoisted_3 = ["checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_icon = _resolveComponent("number-icon")!

  return (_ctx.field.type === 3)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "checkbox-sign",
        style: _normalizeStyle({
      width: `${_ctx.field.width}px`,
      height:
        _ctx.field.isOptionalCheckbox && !_ctx.readonly
          ? `${_ctx.field.height + _ctx.optionalTextHeight}px`
          : `${_ctx.field.height}px`,
      top: `${
        _ctx.field.isOptionalCheckbox && !_ctx.readonly
          ? _ctx.field.positionY - _ctx.optionalTextHeight
          : _ctx.field.positionY
      }px`,
      left: `${_ctx.field.positionX}px`,
    })
      }, [
        (!_ctx.readonly)
          ? (_openBlock(), _createBlock(_component_number_icon, {
              key: 0,
              number: _ctx.order,
              "is-check": _ctx.field.isFilled
            }, null, 8, ["number", "is-check"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([{
        'mt-2': _ctx.readonly,
      }, "checkbox-table"])
        }, [
          (_ctx.field.isOptionalCheckbox && !_ctx.readonly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("common.optionalField")), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("input", {
              type: "checkbox",
              checked: _ctx.field.isChecked,
              onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onUpdate && _ctx.onUpdate(...args))),
              disabled: _ctx.readonly
            }, null, 40, _hoisted_3),
            _createElementVNode("p", {
              class: "text",
              style: _normalizeStyle(
            '--fz:' + _ctx.field.fontSize + 'px;--fz-mob:' + _ctx.fontSizeMob + 'px'
          )
            }, _toDisplayString(_ctx.field.text), 5)
          ])
        ], 2)
      ], 4))
    : _createCommentVNode("", true)
}