
import { defineComponent, inject } from "vue";
import NumberIcon from "@/components/atomics/NumberIcon.vue";
import useInputVars from "@/lib/compositional-logic/useInputVars";
import { computed, ComputedRef } from "@vue/reactivity";
export default defineComponent({
  name: "SignCheckboxField",
  components: {
    NumberIcon,
  },
  props: {
    order: { type: Number, default: 0 },
    field: { type: Object, default: {} as any },
    readonly: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const { getFontSizeMobile } = useInputVars();
    const isMobile = inject("isMobile") as ComputedRef;

    const optionalTextHeight = computed(() => (isMobile.value ? 15 : 20));

    const fontSizeMob = computed(() =>
      getFontSizeMobile(props.field?.fontSize)
    );

    const onUpdate = (val: any) => {
      const payload = {
        value: val.target.checked,
        fieldId: (props.field as any).id || "",
      };
      emit("onInputSignField", payload);
    };

    return { onUpdate, fontSizeMob, optionalTextHeight };
  },
});
