
import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import Chip from "@/components/atomics/Chip.vue";
import Dropdown from "./Dropdown.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import { isEmail, truncate } from "@/lib/utility/stringUtil";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  name: "TransferUserSelect",
  components: {
    Chip,
    AvatarCircle,
    Dropdown,
    Vue3Popper,
  },
  props: {
    items: { type: Array, default: [] as never[] },
    selectedValues: { type: Array, default: [] as never[] },
    permissions: { type: Array, default: [] as never[] },
    itemTypes: { type: String, default: "User" },
    label: { type: String, default: "" },
    limit: { type: Boolean, default: false },
    limitNumber: { type: Number, default: Infinity },
    searchHint: { type: String, default: "" },
    isCreateTag: { type: Boolean, default: true },
    isEnterToCreateTag: { type: Boolean, default: true },
    isShowCompany: { type: Boolean, default: false },
    createUserWithTag: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    defaultDropdrownOption: { default: "" },
    emailError: { type: String, default: "" },
  },
  emits: [
    "onSearchChange",
    "onUpdate",
    "onChange",
    "onChangePermission",
    "onInput",
  ],
  setup(props) {
    const state = reactive({
      selectedItems: [] as any,
      searchQuery: "",
      avatarBaseUrl: process.env.VUE_APP_API_CLOUD_URL,
      selectedPermission: "",
      items2: ref([]),
      inputFocused: false,
      itemRemoving: false,
      errorOfEmailTagField: "",
    });

    watch(
      () => props.defaultDropdrownOption,
      (val) => {
        state.selectedPermission = val;
      }
    );

    watch(
      () => props.selectedValues,
      (val) => {
        state.selectedItems = val;
      }
    );

    watch(
      () => props.emailError,
      (val) => {
        if (val) {
          state.errorOfEmailTagField = val;
        }
      }
    );
    onMounted(() => {
      state.selectedItems = [...props.selectedValues];
    });

    return { ...toRefs(state) };
  },
  methods: {
    isSelectedItem(item: any): boolean {
      if (
        !this.selectedItems.find(
          (user: any) => user.email === item.email || user.id === item.id
        )
      )
        return false;
      else return true;
    },
    handleBlurTransferEmails() {
      if (this.validateTransferEmails()) {
        const item = {
          email: this.searchQuery,
        };
        if (this.selectItem(item)) {
          this.searchQuery = "";
        }
      }
    },
    validateTransferEmails(): boolean {
      if (this.searchQuery) {
        if (!isEmail(this.searchQuery) && this.selectedItems.length === 0) {
          this.errorOfEmailTagField = this.$t("errors.formatEmail");
          return false;
        } else {
          this.errorOfEmailTagField = "";
          return true;
        }
      }
      return false;
    },
    selectItem(item: any): boolean {
      this.$emit("onChange", item);
      if (
        !this.selectedItems.includes(item as never) &&
        this.selectedItems.length < this.limitNumber
      ) {
        this.selectedItems.push(item);
        this.$emit("onUpdate", this.selectedItems);
        this.errorOfEmailTagField = "";
        return true;
      } else return false;
    },
    removeItem(item: any): void {
      this.itemRemoving = true;
      const index = this.selectedItems.indexOf(item as never);
      this.selectedItems.splice(index, 1);
      this.$emit("onUpdate", this.selectedItems);
    },
    createTag(): void {
      if (
        this.searchQuery.length > 0 &&
        this.isEnterToCreateTag &&
        this.validateTransferEmails()
      ) {
        let item;
        const user = this.items.find(
          (item: any) => item.email === this.searchQuery
        );
        if (user) {
          item = { ...(user as any) };
        } else if (this.createUserWithTag)
          item = {
            email: this.searchQuery,
            tag: this.$t("contract.placeholderTag"),
          };
        else
          item = {
            email: this.searchQuery,
          };

        if (this.selectItem(item)) {
          this.searchQuery = "";
          this.searchQueryChange("");
        }
      }
    },
    searchQueryChange(value: any) {
      if (this.searchQuery.length > 320) {
        this.searchQuery = truncate(this.searchQuery, 320, "");
        // Error Can't enter more than 320 character
        this.errorOfEmailTagField = this.$t("contract.contractTransferTagError1");
      } else {
        this.errorOfEmailTagField = "";
        this.$emit(
          "onInput",
          this.searchQuery + (value.isComposing ? value?.data ?? "" : "")
        );
        this.$emit("onSearchChange", this.searchQuery);
      }
    },
    changePermission(id: any) {
      this.selectedPermission = id;
      this.$emit("onChangePermission", id);
    },
    clickOutFocusedBox(e: MouseEvent) {
      if (this.itemRemoving) {
        this.itemRemoving = false;
        return;
      }
      if (!(this.$refs.group as any)?.contains(e.target)) {
        this.inputFocused = false;
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.clickOutFocusedBox);
  },
  deactivated() {
    window.removeEventListener("click", this.clickOutFocusedBox);
  },
});
