
// @ is an alias to /src
import { defineComponent } from "vue";
import xIDIcon from "icons/xid.svg";
import xIDIconGrey from "icons/xid-grey.svg";

export default defineComponent({
  name: "XIDButton",
  props: {
    text: { type: String, required: true },
    enabled: { type: Boolean, default: true },
  },
  data() {
    return {
      xIDIcon: xIDIcon,
      xIDIconGrey: xIDIconGrey,
    };
  },
});
