import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-304c9d79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contract-message mt-5" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "confirm-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.$t('contractGuest.loginByXID'),
        onOnClickOutsidePopup: _ctx.hide,
        onOnHide: _ctx.hide,
        class: "contract-popup"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", {
              class: "message",
              innerHTML: _ctx.$t('contractGuest.requireLoginXIDMessage')
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FlatButton, {
              onOnClick: _ctx.onRequestLoginXID,
              text: _ctx.$t('contractGuest.loginByXID')
            }, null, 8, ["onOnClick", "text"])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}