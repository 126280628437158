import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_icon = _resolveComponent("number-icon")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!

  return (_ctx.field.type === 1)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "textfield-sign",
        style: _normalizeStyle({
      width: `${_ctx.field.width}px`,
      height: `${_ctx.field.height}px`,
      top: `${_ctx.field.positionY}px`,
      left: `${_ctx.field.positionX}px`,
    })
      }, [
        (!_ctx.readonly)
          ? (_openBlock(), _createBlock(_component_number_icon, {
              key: 0,
              number: _ctx.order,
              "is-check": _ctx.field.isFilled
            }, null, 8, ["number", "is-check"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ValidationTextField, {
          style: _normalizeStyle('--fz:' + _ctx.field.fontSize + 'px;--fz-mob:' + _ctx.fontSizeMob + 'px'),
          class: _normalizeClass({ 'mt-2': _ctx.readonly }),
          readonly: _ctx.readonly,
          name: _ctx.field.id,
          value: _ctx.field.text,
          isMultiline: true,
          placeholder: _ctx.placeholder,
          "onUpdate:value": _ctx.onUpdate
        }, null, 8, ["style", "class", "readonly", "name", "value", "placeholder", "onUpdate:value"])
      ], 4))
    : _createCommentVNode("", true)
}