
import { computed, defineComponent } from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import Popup from "@/components/popups/Popup.vue";
import { useStore } from "vuex";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import { RepositoryFactory, SignRepository } from "@/lib/https";

export default defineComponent({
  name: "ContractSelectPopup",
  components: {
    FlatButton,
    Popup,
  },
  props: {
    contractTitle: { type: String, default: "", required: true },
    contractId: { type: String, default: "" },
    contracts: { type: Array, default: [] as never[] },
    isGuest: { type: Boolean, default: false },
  },
  emits: ["onHide", "onSelect"],
  setup(props, { emit }) {
    const store = useStore();
    const isShow = computed(() => store.state.popup.SelectFileXID);

    const { getSignedContractVarsConclusion, getAccessCode } =
      useSignFunctions();

    const { signFileGuest, signFile } =
      RepositoryFactory.getRepository<SignRepository>(SignRepository);

    const hide = () => {
      store.commit("popup/unsetPopup", "SelectFileXID");
      emit("onHide");
    };

    const select = async (file: any) => {
      const signedContractVarsConclusion = getSignedContractVarsConclusion(
        file.contractVars || []
      );
      const accessCode = getAccessCode(props.contractId);
      const signInformation = !props.isGuest
        ? await signFile(props.contractId, file.id, accessCode, {
            contractVars: signedContractVarsConclusion,
          })
        : await signFileGuest(file.id, accessCode, {
            contractVars: signedContractVarsConclusion,
          });

      if (signInformation) {
        emit("onSelect", {
          codeNumber: signInformation.codeNumber,
          fileId: file.id,
        });
      }
    };

    return {
      isShow,
      hide,
      select,
    };
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
