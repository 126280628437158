
import { computed, defineComponent, ref } from "vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Popup from "@/components/popups/Popup.vue";
import { useStore } from "vuex";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import { RepositoryFactory, SignRepository } from "@/lib/https";
import { useRouter } from "vue-router";
import { useForm } from "vee-validate";

export default defineComponent({
  name: "SignCodePopup",
  components: {
    FlatButton,
    TextButton,
    ValidationTextField,
    Popup,
  },
  props: {
    contractTitle: { type: String, default: "", required: true },
    contractId: { type: String, default: "", required: true },
    isGuest: { type: Boolean, default: false },
    signerEmail: { type: String, default: "" },
    signedFields: { type: Array, default: [] as any },
  },
  setup(props) {
    const code = ref("");
    const isLoading = ref(false);

    const store = useStore();
    const router = useRouter();
    const isShow = computed(() => store.state.popup.SignCode);

    const { setFieldError } = useForm();
    const { getSignedContractVarsConclusion, getAccessCode } =
      useSignFunctions();
    const { signContract, signContractGuest, requestSignVerificationCode, requestSignGuest } =
      RepositoryFactory.getRepository<SignRepository>(SignRepository);

    const onSign = async () => {
      const signedContractVarsConclusion = getSignedContractVarsConclusion(
        props.signedFields
      );

      const accessCode = getAccessCode(props.contractId);

      const signatureFieldAndAccessCode = {
        signingVerificationCode: code.value,
        contractVars: signedContractVarsConclusion,
      };

      try {
        const response = !props.isGuest
          ? await signContract(
              props.contractId,
              accessCode,
              signatureFieldAndAccessCode
            )
          : await signContractGuest(accessCode, signatureFieldAndAccessCode);
        if (response) {
          hide();
          navigateToSignDone();
        }
      } catch (e: any) {
        isLoading.value = false;
        if (e.data) setFieldError("signPasscode", e.data.message ?? "");
      }
    };

    const navigateToSignDone = () => {
      router.push({
        name: !props.isGuest ? "SignDone" : "GuestSignDone",
        query: {
          contractId: props.contractId,
        },
      });
    };

    const hide = () => {
      code.value = "";
      store.commit("popup/unsetPopup", "SignCode");
    };

    const onRequestCodeAgain = async () => {
      !props.isGuest
          ? await requestSignVerificationCode(props.contractId)
          : await requestSignGuest();
    }

    return { code, isShow, isLoading, onSign, hide, onRequestCodeAgain };
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
