import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6e2c002"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body-container" }
const _hoisted_2 = { class: "row-input" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.$t('contractGuest.xidPinSignTitle'),
        closeButton: _ctx.isLoading ? false : true,
        onOnClickOutsidePopup: _ctx.onClose,
        onOnHide: _ctx.onClose
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([{ 'pb-2': _ctx.isSuccess }, "body"])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pinCode, (pin, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createElementVNode("input", {
                      disabled: "",
                      class: _normalizeClass([{
                'default-border': !_ctx.isError && !_ctx.isSuccess,
                'error-border': _ctx.isError,
                'success-border': _ctx.isSuccess,
              }, "mr-1"]),
                      type: "text",
                      value: pin
                    }, null, 10, _hoisted_3)
                  ]))
                }), 128))
              ]),
              (!_ctx.isLoading && !_ctx.isSuccess && !_ctx.isError)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    innerHTML: _ctx.$t('contractGuest.messageXIDPin'),
                    class: "message mt-2"
                  }, null, 8, _hoisted_4))
                : (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      innerHTML: _ctx.$t('contractGuest.pinLoading'),
                      class: "message mt-2"
                    }, null, 8, _hoisted_5))
                  : (_ctx.isSuccess)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 2,
                        innerHTML: _ctx.$t('contractGuest.pinSuccess'),
                        class: "message mt-2"
                      }, null, 8, _hoisted_6))
                    : (_ctx.isError)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 3,
                          innerHTML: _ctx.$t('contractGuest.pinError'),
                          class: "message mt-2"
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true),
              (_ctx.isSuccess)
                ? (_openBlock(), _createBlock(_component_flat_button, {
                    key: 4,
                    class: "confirm-btn mt-5",
                    text: _ctx.$t('common.next'),
                    onOnClick: _ctx.onSuccess
                  }, null, 8, ["text", "onOnClick"]))
                : (_ctx.isError)
                  ? (_openBlock(), _createBlock(_component_flat_button, {
                      key: 5,
                      class: "confirm-btn mt-5",
                      text: _ctx.$t('contractGuest.pinErrorButton'),
                      onOnClick: _ctx.onDone
                    }, null, 8, ["text", "onOnClick"]))
                  : _createCommentVNode("", true)
            ])
          ], 2)
        ]),
        _: 1
      }, 8, ["title", "closeButton", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}