import moment from "moment";
import "moment-timezone";

import * as htmlToImage from "html-to-image";

const yearPattern = new RegExp(/y+/, "g");
const monthPattern = new RegExp(/M+/, "g");
const dayPattern = new RegExp(/d+/, "g");

export const formatDate = (date: string, format: string): string => {
  const timeDifference = moment.tz.guess();
  return moment(date).tz(timeDifference).format(format);
};

export const formatLocalDate = (date: Date, format: string): string => {
  if (!date) return "";
  const dateToFormat = new Date(date);
  const yearReplaced = format.replaceAll(
    yearPattern,
    dateToFormat.getFullYear().toString()
  );
  const monthReplaced = yearReplaced.replaceAll(
    monthPattern,
    (dateToFormat.getMonth() + 1).toString()
  );
  const dayReplaced = monthReplaced.replaceAll(
    dayPattern,
    dateToFormat.getDate().toString()
  );
  return dayReplaced;
};

export const isEmptyObject = (object: any): boolean => {
  for (const key in object) return false;
  return true;
};

export const formatFolderPath = (path: string): string => {
  let finalPath = "";
  if (!path) return "";
  const splitted = path.split(">");
  if (splitted.length < 4) {
    splitted.forEach((element, index) => {
      finalPath =
        finalPath +
        element.split("|")[1] +
        (splitted.length - 1 == index ? "" : " / ");
    });
  } else {
    finalPath =
      splitted[0].split("|")[1] +
      " / ... / " +
      splitted[splitted.length - 3].split("|")[1] +
      " / " +
      splitted[splitted.length - 2].split("|")[1] +
      " / " +
      splitted[splitted.length - 1].split("|")[1];
  }
  return finalPath;
};

export const formatFolderSearchPath = (path: string): string[] => {
  let finalPath = "";
  let lastFileName = "";
  if (!path) return ["", ""];
  const splitted = path.split(">");
  if (splitted.length < 4) {
    splitted.forEach((element, index) => {
      if (index < splitted.length - 1)
        finalPath = finalPath + element.split("|")[1] + "/";
    });
    lastFileName = splitted[splitted.length - 1].split("|")[1];
  } else {
    finalPath =
      splitted[0].split("|")[1] +
      " / ... / " +
      splitted[splitted.length - 3].split("|")[1] +
      " / " +
      splitted[splitted.length - 2].split("|")[1] +
      " / ";
    lastFileName = splitted[splitted.length - 1].split("|")[1];
  }
  return [finalPath, lastFileName];
};

export const getListFolderFromPath = (path: string): Array<never> => {
  if (!path) return [] as never[];
  const listFolder = [] as Array<never>;
  const splitted = path.split(">");
  splitted.forEach((element, index) => {
    if (index > 0) {
      const folderArr = element.split("|");
      const folder = { id: folderArr[0], name: folderArr[1] } as never;
      listFolder.push(folder);
    }
  });
  return listFolder;
};

export const getImageFromHtmlElement = async (
  element: HTMLElement
): Promise<string> => {
  //for case remove font
  // const fontEmbedCss = `@font-face {
  //     font-family: 'Noto Sans JP';
  //     font-style: normal;
  //     font-weight: 500;
  //     font-display: swap;
  //     src: url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap) format('woff2');
  //   }`;
  // for case apply all font to svg file -> it store 16mb font to svg file -> unlock comment
  const fontEmbedCss = await htmlToImage.getFontEmbedCSS(element);
  const img = await htmlToImage.toSvg(element, {
    fontEmbedCSS: fontEmbedCss,
    cacheBust: true,
    canvasHeight: element.clientHeight * 3,
    canvasWidth: element.clientWidth * 3,
    style: {
      fontWeight: "500",
    },
  });
  return img;
};

export const resizeImage = async (file: File) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);

  return new Promise((resolve) => {
    const result = {
      fileUpload: null,
      base64: "",
    };
    img.onload = async () => {
      const outputCanvas: HTMLCanvasElement = document.createElement("canvas");
      const outputCanvasContext = outputCanvas.getContext("2d");
      const constantSize = 256;

      if (img.height >= img.width) {
        outputCanvas.width = constantSize;
        outputCanvas.height = constantSize;
        outputCanvasContext?.drawImage(
          img,
          0,
          Math.floor((img.height - img.width) / 2),
          img.width,
          img.width,
          0,
          0,
          constantSize,
          constantSize
        );
      } else {
        outputCanvas.width = constantSize;
        outputCanvas.height = constantSize;
        outputCanvasContext?.drawImage(
          img,
          Math.floor((img.width - img.height) / 2),
          0,
          img.height,
          img.height,
          0,
          0,
          constantSize,
          constantSize
        );
      }

      outputCanvas.toBlob((blob) => {
        result.fileUpload = blob as any;
        result.base64 = outputCanvas.toDataURL();
        resolve(result);
      });
    };
  });
};

export const getFileExtension = (name?: string) =>
  name ? name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2) : "";

export const getStampWidthFromMM = (stampSizeMM: number) => {
  return (stampSizeMM * 41 * 2) / 15;
};

export const getStampHeightFromWidth = (stampWidth: number) => {
  return stampWidth;
};
