
import { defineComponent } from "vue";
import AvatarCircle from "../organisms/AvatarCircle.vue";

export default defineComponent({
  components: { AvatarCircle },
  name: "Chip",
  props: {
    avatar: { type: String, default: "" },
    text: { type: String, require: true },
    canRemove: { type: Boolean, default: true },
  },
  methods: {
    onRemove() {
      this.$emit("onRemove");
    },
  },
});
