import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3664b85b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contract-message mt-5" }
const _hoisted_2 = { class: "message" }
const _hoisted_3 = { class: "message" }
const _hoisted_4 = { class: "confirm-button" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.contractTitle + _ctx.$t('sign.signingContract'),
        onOnClickOutsidePopup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoading ? null : _ctx.unsetPopup('RequestCode'))),
        onOnHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isLoading ? null : _ctx.unsetPopup('RequestCode'))),
        class: "contract-popup"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("sign.popupMessage1")), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("sign.popupMessage2")), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "message",
                  innerHTML: _ctx.$t('sign.inSignatureText')
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            _createVNode(_component_FlatButton, {
              onOnLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoading = $event)),
              "on-click": _ctx.is2FARequired ? _ctx.onRequestSign : _ctx.onSign,
              text: _ctx.$t('sign.sign')
            }, null, 8, ["on-click", "text"])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}