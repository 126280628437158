
import { computed, defineComponent, inject } from "vue";
import Tag from "@/components/atomics/Tag.vue";

import closeIcon from "@/assets/icons/close.svg";
import Notification from "@/components/atomics/Notification.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Popup",
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
    hasHeaderDevider: { type: Boolean },
    tagText: { type: String, default: "" },
  },
  components: {
    Tag,
    Notification,
  },
  emit: ["onClickOutsidePopup", "onHide"],
  setup() {
    const store = useStore();
    const isMobile = inject("isMobile") as any;
    return {
      closeIcon: closeIcon,
      store,
      isMobile,
      showInPopup: computed(() => store.state.notification.showInPopup),
    };
  },
  methods: {
    clickOutPopup(e: any): void {
      if (
        e.target.className === "popup-wrapper" ||
        e.target.className === "popup-wrapper contract-popup"
      ) {
        this.$emit("onClickOutsidePopup");
        this.store.commit("notification/removeAllNotification");
      }
    },

    onMousedown(e: any): void {
      e.preventDefault();
    },

    hidePopup(): void {
      this.$emit("onHide");
      this.store.commit("notification/removeAllNotification");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
