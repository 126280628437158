
import { defineComponent, inject, watch } from "vue";
import NumberIcon from "@/components/atomics/NumberIcon.vue";
export default defineComponent({
  name: "SideContractInfo",
  components: {
    NumberIcon,
  },
  setup(props) {
    const isMobile = inject("isMobile");
    return { isMobile };
  },
  props: {
    components: { type: Object, default: {} as never },
    contractName: { type: String, default: "" },
  },
});
